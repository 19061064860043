import React from "react";
import { Route, Routes } from "react-router-dom";
import About from "../pages/About/About";
import Home from "../pages/Home/Home";
import Analytics from "../pages/Analytics/Analytics";
import RegisterPage from "../pages/RegisterPage";
import LoginPage from "../pages/LoginPage";
import ConfirmPage from "../pages/ConfirmPage";
import ProfilePage from "../pages/ProfilePage";
import MyPetitionsPage from "../pages/Petitions/MyPetitionsPage";
import CreatePetitionPage from "../pages/Petitions/CreatePetitionPage";
import PreviewPetition from "../components/petitions/PreviewPetition/PreviewPetition";
import PetitionDetailsPage from "../pages/Petitions/PetitionDetailsPage";
import PetitionEditPage from "../pages/Petitions/PetitionEditPage";
import VerifyPage from "../pages/VerifyPage/VerifyPage";
import ConfirmEmailChangePage from "../pages/ConfirmEmailChangePage";
import VerificationInfoPage from "../pages/VerificationInfoPage";
import PetitionSearchPage from "../pages/Petitions/PetitionSearchPage";
import DnsRecordsPage from "../pages/DnsRecordsPage";
import ConstructorPage from "../pages/ConstructorPage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import ForgotPasswordEmailPage from "../pages/ForgotPasswordEmailPage";
import PasswordRecoveryPage from "../pages/PasswordRecoveryPage";
import MyPetitionDetailsPage from "../pages/Petitions/MyPetitionDetailsPage";
import NotFoundPage from "../pages/NotFoundPage/NotFoundPage";
import PetitionsPage from "../pages/Petitions/PetitionsPage";
import CreatePetitionSuccessPage from "../pages/Petitions/CreatePetitionSuccessPage";
import ErrorPage from "../pages/ErrorPage";
import NewsPage from "../pages/NewsPage";
import NewsInfoPage from "../pages/NewsInfoPage";
import AlternativaVerificationPage from "../pages/AlternativeVerificationPage";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import Contacts from "../pages/Contacts/Contacts";

const MainRoutes = () => {
  const ROUTES = [
    {
      id: 1,
      path: "/",
      element: <Home />,
    },
    {
      id: 2,
      path: "/petitions",
      element: <PetitionsPage />,
    },
    {
      id: 3,
      path: "/analytics",
      element: <Analytics />,
    },
    {
      id: 4,
      path: "/about",
      element: <About />,
    },
    {
      id: 5,
      path: "/register",
      element: <RegisterPage />,
    },
    {
      id: 6,
      path: "/login",
      element: <LoginPage />,
    },
    {
      id: 7,
      path: "/confirm",
      element: <ConfirmPage />,
    },
    {
      id: 8,
      path: "/profile",
      element: <ProfilePage />,
    },
    {
      id: 9,
      path: "/mypetitions",
      element: <MyPetitionsPage />,
    },
    {
      id: 10,
      path: "/create-petition",
      element: <CreatePetitionPage />,
    },
    {
      id: 11,
      path: "/preview-petition",
      element: <PreviewPetition />,
    },
    {
      id: 12,
      path: "/petitions/:id",
      element: <PetitionDetailsPage />,
    },
    {
      id: 13,
      path: "/edit-petition/:id",
      element: <PetitionEditPage />,
    },
    {
      id: 14,
      path: "/verification",
      element: <VerifyPage />,
    },
    {
      id: 15,
      path: "/confirm-email-change",
      element: <ConfirmEmailChangePage />,
    },
    {
      id: 16,
      path: "/verification-info",
      element: <VerificationInfoPage />,
    },
    {
      id: 17,
      path: "/petition-search",
      element: <PetitionSearchPage />,
    },
    {
      id: 18,
      path: "/dns-records",
      element: <DnsRecordsPage />,
    },
    {
      id: 19,
      path: "/constructor",
      element: <ConstructorPage />,
    },
    {
      id: 20,
      path: "/forgot-password",
      element: <ForgotPasswordPage />,
    },
    {
      id: 21,
      path: "/forgot-password-email",
      element: <ForgotPasswordEmailPage />,
    },
    {
      id: 22,
      path: "/password-recovery",
      element: <PasswordRecoveryPage />,
    },
    {
      id: 23,
      path: "/mypetitions/:id",
      element: <MyPetitionDetailsPage />,
    },
    {
      id: 24,
      path: "/create-petition-success",
      element: <CreatePetitionSuccessPage />,
    },
    {
      id: 25,
      path: "/error",
      element: <ErrorPage />,
    },
    {
      id: 26,
      path: "/news",
      element: <NewsPage />,
    },
    {
      id: 27,
      path: "/news/:id",
      element: <NewsInfoPage />,
    },
    {
      id: 28,
      path: "/verification-info/alternative-verification",
      element: <AlternativaVerificationPage />,
    },
    {
      id: 29,
      path: "/privacy",
      element: <PrivacyPolicy />,
    },
    {
      id: 30,
      path: "/contacts",
      element: <Contacts />,
    },
  ];
  return (
    <Routes>
      {ROUTES.map((route) => (
        <Route key={route.id} path={route.path} element={route.element} />
      ))}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default MainRoutes;
