import { useNavigate } from "react-router-dom";
import CloseModalLogout from "./icons/x-close.png";
import { toast } from "react-toastify";
import api from "../../../../helpers/axiosConfig";
import { logout } from "../../../../helpers/functions";
import { useState } from "react";
import LoaderButton from "../../../ui/LoaderButton/LoaderButton";

const DeleteAccountModal = ({ setDeleteAccountModal }) => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleDelete = async (e) => {
    setLoading(true);
    try {
      const res = await api.post(`/api/users/delete/`);
      console.log(res);
      toast.success("Вы успешно удалили аккаунт.");
      logout();
      navigate("/login");
    } catch (err) {
      console.log("[DELETE ACCOUNT error]", err);
      toast.error("Не удалось удалить аккаунт");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="logout-modal">
      <div className="logout-modal__content">
        <div className="logout-modal__top">
          <p>Удаление аккаунта</p>
          <img
            src={CloseModalLogout}
            alt="close"
            onClick={() => setDeleteAccountModal(false)}
          />
        </div>
        <div className="logout-modal__middle">
          Вы действительно хотите удалить аккаунт?
        </div>
        <div className="logout-modal__bottom">
          <button
            className="logout-modal__cancel"
            onClick={() => setDeleteAccountModal(false)}
          >
            Отмена
          </button>
          <button className="logout-modal__btn" onClick={handleDelete}>
            {loading ? <LoaderButton /> : "Удалить"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountModal;
