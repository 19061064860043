import React from "react";
import EditImg from "../../ui/images/edit.svg";
import ChangePasswordModal from "./ChangePasswordModal/ChangePasswordModal";

const ProfilePasswordInput = ({ setIsChangePassword, isChangePassword }) => {
  return (
    <div className="profile__inputs" id="password-block">
      <input
        type="password"
        placeholder="• • • • • • • • •"
        name="password"
        id="passwordInput"
        onClick={() => setIsChangePassword(true)}
      />
      <img
        src={EditImg}
        alt="edit"
        onClick={() => {
          setIsChangePassword(true);
        }}
      />
      {/* {isChangePassword && (
        <ChangePasswordModal setIsChangePassword={setIsChangePassword} />
      )} */}
    </div>
  );
};

export default ProfilePasswordInput;
