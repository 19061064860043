import "./NewsDetail.scss";
import message from "../icons/message.svg";
import calendar from "../icons/calendar.svg";
import ReviewSection from "../reviewSection/ReviewSection";
import Loader from "../../ui/Loader/Loader";
import { useTranslation } from "react-i18next";

const NewsDetails = ({ newsDetails, loadingDetails }) => {
  const [t] = useTranslation("global");
  const months = {
    "01": t("profile.January"),
    "02": t("profile.February"),
    "03": t("profile.March"),
    "04": t("profile.April"),
    "05": t("profile.May"),
    "06": t("profile.June"),
    "07": t("profile.July"),
    "08": t("profile.August"),
    "09": t("profile.September"),
    10: t("profile.October"),
    11: t("profile.November"),
    12: t("profile.December"),
  };

  const formatDate = (date) => {
    const year = date.slice(0, 4);
    const month = months[date.slice(5, 7)];
    const day = date.slice(8, 10).replace(/^0/, ''); 
    return `${month} ${day}, ${year}`;
  };

  const renderParagraphs = (text) => {
    if (!text) return null;
  
    const paragraphs = text.split('\\r\\n\\r\\n').map((paragraph, index) => <p key={index}>{paragraph}</p>)
    return paragraphs;
  };

  return loadingDetails ? (
    <Loader />
  ) : (
    <div className="news-details page-padding">
      {newsDetails ? (
        <>
          <h2>{newsDetails?.title}</h2>

          <div className="news-details__img">
            <img
              id="news-img"
              src={newsDetails.image}
              alt={newsDetails.title}
            />
            <div className="news-details__date-and-reviews">
              <div className="news-details__date">
                <img src={calendar} alt="Calendar Icon" />
                <p>
                  {formatDate(newsDetails.date)}
                </p>
              </div>

              <div className="news-details__reviews">
                <img src={message} alt="Comment Icon" />
                <p>
                  {t("news.comments")}: {newsDetails.reviews?.length || 0}
                </p>
              </div>
            </div>
          </div>

          <div className="news-details__text">
            <h3>{newsDetails.title}</h3>
            <div style={{  whiteSpace: 'pre-line', wordBreak: 'break-word', overflowWrap: 'break-word' }}>
            {renderParagraphs(newsDetails.text)} 
            </div>
          </div>
          <ReviewSection
            reviews={newsDetails.reviews}
            news_id={newsDetails.id}
          />
        </>
      ) : (
        <p>{t("news.noDetails")}</p>
      )}
    </div>
  );
};

export default NewsDetails;
