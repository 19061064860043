import { useDispatch, useSelector } from "react-redux";
import "./Analytics.scss";
import { useEffect, useState } from "react";
import { getAnalytics } from "../../store/analytics/analyticsActions";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Analytics = () => {
  const getCurrentDate = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  };

  const { loading, analyticsData } = useSelector((state) => state.analytics);
  const [petitionsByTag, setPetitionsByTag] = useState({});
  const { t } = useTranslation("global");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAnalytics());
  }, []);

  useEffect(() => {
    if (analyticsData && analyticsData["Петиции по тегам"]) {
      const data = {};
      analyticsData["Петиции по тегам"].forEach((tag) => {
        const tagName = Object.keys(tag)[0];
        const tagCount = Object.values(tag)[0];
        if (
          [
            "Семья",
            "Налоги",
            "Миграция",
            "Образование",
            "Судебная система",
          ].includes(tagName)
        ) {
          data[tagName] = tagCount;
        }
      });
      const sortedPetitionsByTag = Object.fromEntries(
        Object.entries(data).sort(([, a], [, b]) => b - a)
      );
      setPetitionsByTag(sortedPetitionsByTag);
    }
  }, [analyticsData]);

  const data = {
    labels: Object.keys(petitionsByTag),
    datasets: [
      {
        label: t("analytics.amountPetitions"),
        data: Object.values(petitionsByTag),
        backgroundColor: "#3695D8",
      },
    ],
  };

  const options = {
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            return Number.isInteger(value) ? value : "";
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += Math.round(context.parsed.y).toLocaleString();
            }
            return label;
          },
        },
      },
    },
  };

  return (
    <>
      {loading ? (
        <div className="loadingContainer">
          <h3>{t("loading.loading")}</h3>
        </div>
      ) : (
        <div className="analytics">
          <div className="analytics__content container">
            <p style={{ color: "#3695D8", fontWeight: "600" }}>{getCurrentDate()}</p>
            <h1 style={{ fontSize: "3rem" }}>Аналитика</h1>
            <p
              style={{
                color: "#667085",
                fontSize: "1.3rem",
                textAlign: "center",
              }}
            >
              На этой странице приведена статистика по законодательным инициативам.
            </p>
            <div className="analytics__block">
              <div className="analytics__item">
                <h5 className="analytics__title">
                  {t("analytics.totalPetitions")}
                </h5>
                <div className="analytics__count">
                  {analyticsData?.["Всего петиций"]}
                </div>
                <p>{t("analytics.date") + getCurrentDate()}</p>
              </div>
              <div className="analytics__item">
                <h5 className="analytics__title">
                  {t("analytics.thresholdPetitions")}
                </h5>
                <div className="analytics__count">0</div>
                <p>{t("analytics.date") + getCurrentDate()}</p>
              </div>
            </div>
            <div className="analytics__block">
              <div className="analytics__item">
                <h5 className="analytics__title">
                  {t("analytics.moderationPetitions")}
                </h5>
                <div className="analytics__count">
                  {analyticsData?.["Петиций на модерации"]}
                </div>
                <p>{t("analytics.date") + getCurrentDate()}</p>
              </div>
              <div className="analytics__item">
                <h5 className="analytics__title">
                  {t("analytics.approvedPetitions")}
                </h5>
                <div className="analytics__count">
                  {analyticsData?.["Опубликованных петеций"]}
                </div>
                <p>{t("analytics.date") + getCurrentDate()}</p>
              </div>
            </div>
            <div>
              Статистика обновляется раз в день и может быть неполной.
            </div>
            <div>
              Статистика по пользователям и посещениям сайта приведена внизу страницы.
            </div>
            <h3>{t("analytics.analyticsChartTitle")}</h3>
            {/* <Bar
              data={{
                labels: Object.keys(petitionsByTag),
                datasets: [
                  {
                    label: 'Количество петиций',
                    data: Object.values(petitionsByTag),
                    backgroundColor: '#3695D8',
                  },
                ],
              }}
            /> */}
            <Bar className="analytics__bar" data={data} options={options} />

            <h3>Хотите знать больше?</h3>
            <div>
              Напишите официальный запрос через форму обратной связи или через email, и мы будем рады ответить на ваши вопросы.
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Analytics;
