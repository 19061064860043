import "./Contacts.scss";
import axios, { AxiosError } from "axios";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { API } from "../../helpers/consts";
import LoaderButton from "../../components/ui/LoaderButton/LoaderButton";

function Contacts() {
  const { t } = useTranslation("global");

  const schema = z.object({
    first_name: z.string().optional(),
    last_name: z.string().optional(),
    email: z.string().optional(),
    phone_number: z.string().optional(),
    message: z.string().nonempty({ message: t("contacts.messageReq") }),
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(`${API}/api/feedback/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log(response.data);
      toast.success(t("contacts.success_message"));
    } catch (error) {
      console.error("Error submitting appeal:", error);
      if (error instanceof AxiosError) {
        let message = "Недопустимый";
        if (error.response?.data.phone_number) {
          message += " телефонный номер";
        }
        if (error.response?.data.email) {
          message += ", email";
        }
        toast.error(message);
      }
    }
  };
  return (
    <div className="contacts container">
      <div className="contacts__info">
        <div>
          <h1>{t("contacts.address")}</h1>
          <p>{t("contacts.address_content")}</p>
        </div>
        <div>
          <h1>{t("contacts.phone")}</h1>
          <p>(0312) 39 40 38</p>
        </div>
        <div>
          <h1>Email</h1>
          <p>kyrgyzstan@transparency.org</p>
        </div>
      </div>

      <form className="contacts__form" onSubmit={handleSubmit(onSubmit)}>
        <h1>{t("contacts.header")}</h1>
        <div className="input-forms">
          <div className="inp-block">
            <label htmlFor="firstName">{t("contacts.first_name")}</label>
            <input type="text" {...register("first_name")} />
            {errors.first_name && (
              <span className="error-message">{errors.first_name.message}</span>
            )}
          </div>
          <div className="inp-block">
            <label htmlFor="lastName">{t("contacts.last_name")}</label>
            <input type="text" {...register("last_name")} />
            {errors.last_name && (
              <span className="error-message">{errors.last_name.message}</span>
            )}
          </div>
          <div className="inp-block">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              name="email"
              {...register("email")}
              placeholder="user@gmail.com"
              className={errors.email ? "input-field-err" : "input-field"}
            />
            {errors.email && (
              <span className="error-message">{errors.email.message}</span>
            )}
          </div>

          <div className="inp-block">
            <label htmlFor="phoneNumber">{t("contacts.phone_number")}</label>
            <input
              type="text"
              {...register("phone_number", { required: false })}
            />
            {errors.phone_number && (
              <span className="error-message">
                {errors.phone_number.message}
              </span>
            )}
          </div>

          <div className="inp-block">
            <label htmlFor="message">{t("contacts.message")}</label>
            <textarea
              className={
                errors.message ? "inp-block-textarea-err" : "inp-block-textarea"
              }
              {...register("message", { required: true })}
            ></textarea>
            {errors.message && (
              <span className="error-message">{errors.message.message}</span>
            )}
          </div>

          <div>
            <input
              type="checkbox"
              id="consentCheckbox"
              name="consent"
              required
            />
            <label
              htmlFor="consentCheckbox"
              style={{
                color: "#667085",
                fontSize: "16px",
                fontWeight: "400",
                marginLeft: "10px",
              }}
            >
              {t("contacts.consent")}
            </label>
          </div>
          <div className="btn-container">
            <button className="contacts__button" type="submit">
              {isSubmitting ? <LoaderButton /> : t("contacts.button")}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Contacts;
