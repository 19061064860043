import s from "./ChangePasswordModal.module.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { changePassword } from "../../../../store/account/accountActions";
import HidePasswordImg from "../../../ui/images/eye-off.svg";
import ShowPasswordImg from "../../../ui/images/eye.svg";
import { clearStatusState } from "../../../../store/account/accountSlice";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import LoaderButton from "../../../ui/LoaderButton/LoaderButton";

const schema = z
  .object({
    old_password: z.string().nonempty("Старый пароль обязателен"),
    new_password1: z.string().min(8, "Минимум 8 символов"),
    new_password2: z.string().min(8, "Минимум 8 символов"),
  })
  .refine((data) => data.new_password1 === data.new_password2, {
    message: "Пароли не совпадают",
    path: ["password_repeat"],
  });

const ChangePasswordModal = ({ setIsChangePassword }) => {
  const { loading, status, errorStatus } = useSelector(
    (state) => state.account
  );

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [t] = useTranslation("global");

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    return () => {
      dispatch(clearStatusState());
    };
  }, []);

  const onSubmit = async (data) => {
    console.log(data);
    dispatch(changePassword(data));
  };
  return (
    <>
      {loading ? (
        <div className="loadingContainer">
          <h3>{t("loading.loading")}</h3>
        </div>
      ) : (
        <div className={s.changePassword}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={s.changePassword__content}
          >
            <h3>{t("profile.changePassword")}</h3>
            <label htmlFor="old-password" className={s.changePassword__label}>
              {t("profile.oldPassword")}
            </label>
            <div className={s.changePassword__inpBlock}>
              <input
                className={s.changePassword__inp}
                type={showOldPassword ? "text" : "password"}
                placeholder={t("profile.enterOldPass")}
                {...register("old_password", { required: true })}
              />
              <img
                className={s.changePassword__showEye}
                src={showOldPassword ? HidePasswordImg : ShowPasswordImg}
                alt="eye"
                onClick={() => setShowOldPassword(!showOldPassword)}
              />
            </div>
            {errors.old_password && (
              <div className={s.recovery__errorText}>
                {errors.old_password.message}
              </div>
            )}
            <div
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                textAlign: "right",
                paddingRight: "1rem",
              }}
            >
              <NavLink
                to="/forgot-password-email"
                style={{
                  color: "#489FE1",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                {t("profile.forgotPassword")}
              </NavLink>
            </div>
            <label htmlFor="new-password" className={s.changePassword__label}>
              {t("profile.newPassword")}
            </label>
            <div className={s.changePassword__inpBlock}>
              <input
                className={
                  errors.new_password1
                    ? s.changePassword__inpError
                    : s.changePassword__inp
                }
                type={showNewPassword ? "text" : "password"}
                placeholder={t("profile.enterNewPass")}
                {...register("new_password1", { required: true })}
              />
              <img
                className={s.changePassword__showEye}
                src={showNewPassword ? HidePasswordImg : ShowPasswordImg}
                alt="eye"
                onClick={() => setShowNewPassword(!showNewPassword)}
              />
            </div>
            {errors.new_password1 && (
              <div className={s.recovery__errorText}>
                {errors.new_password1.message}
              </div>
            )}
            <p style={{ marginTop: "0", marginBottom: "1.3rem" }}>
              {t("profile.rules")}
            </p>
            <label
              htmlFor="confirm-password"
              className={s.changePassword__label}
            >
              {t("profile.repeatPassword")}
            </label>
            <div className={s.changePassword__inpBlock}>
              <input
                className={
                  errors.new_password2
                    ? s.changePassword__inpError
                    : s.changePassword__inp
                }
                type={showConfirmPassword ? "text" : "password"}
                placeholder={t("profile.repeatPassword")}
                name="confirm-password"
                {...register("new_password2", { required: true })}
              />
              <img
                className={s.changePassword__showEye}
                src={showConfirmPassword ? HidePasswordImg : ShowPasswordImg}
                alt="eye"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            </div>
            {errors.new_password2 && (
              <div className={s.recovery__errorText}>
                {errors.new_password2.message}
              </div>
            )}

            {errors.root && (
              <div className={s.recovery__errorText}>{errors.root.message}</div>
            )}
            {status && (
              <div className={s.changePassword}>
                <div className={s.changePassword__content}>
                  <p>{t("profile.passwordChanged")}</p>
                  <button
                    className={s.changePassword__save}
                    onClick={() => {
                      setIsChangePassword(false);
                    }}
                  >
                    OK
                  </button>
                </div>
              </div>
            )}

            <button className={s.changePassword__save} type="submit">
              {isSubmitting ? <LoaderButton /> : t("profile.save")}
            </button>
            <button
              className={s.changePassword__cancel}
              onClick={() => setIsChangePassword(false)}
            >
              {t("profile.cancel")}
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default ChangePasswordModal;
