import "./About.scss";
import MainImage from "../../components/ui/images/Content.png";
import ArrowImg from "../../components/ui/images/arrow.svg";
import ArrowUpImg from "../../components/ui/images/arrow-up.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";
const About = () => {
  const [isOpenArrow1, setIsOpenArrow1] = useState(false);
  const [isOpenArrow2, setIsOpenArrow2] = useState(false);
  const [isOpenArrow3, setIsOpenArrow3] = useState(false);
  const [isOpenArrow4, setIsOpenArrow4] = useState(false);
  const [isOpenArrow5, setIsOpenArrow5] = useState(false);
  const [isOpenArrow6, setIsOpenArrow6] = useState(false);
  const { t } = useTranslation("global");

  return (
    <div className='about container'>
      {/*
      <div className='about__image'>
        <img src={MainImage} alt="main" />
      </div>*/}
      <div className='about__info2'>
        <div>
          <h2>{t("about.about_us")}</h2>
       </div>
       <div>
       <p>{t("about.text1")}</p>
       </div>
       <div>
       <p>{t("about.text2")}</p>
       </div>
       <div>
       <p>{t("about.text3")}</p>
       </div>
      </div>
      
    </div>
  )
}

export default About