import "./Home.scss";
import ArrowRight from "../../components/ui/images/arrow-right.svg";
import MoreImg from "../../components/ui/images/More Than.svg";
import TickImg from "../../components/ui/images/Badge.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getPetitions } from "../../store/petition/petitionActions";
import { isUserVerified } from "../../helpers/functions";
import { useNavigate } from "react-router-dom";
import { checkUserLogin } from "../../helpers/functions";
import CloseModal from "../../components/ui/images/close-modal.svg";
import MainImg from "../../components/ui/images/main-page.png";
import ArrowImg from "../../components/ui/images/Main-arrow (2).png";
import BlueArrow from "../../components/ui/images/blueArrow.png";
import PetitionsFilter from "../../components/petitions/PetitionsFilter.jsx/PetitionsFilter";
import Petition from "../../components/petitions/Petition/Petition";

const Home = () => {
  const { petitions, loading } = useSelector((state) => state.petitions);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isVerifyAccount, setisVerifyAccount] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const firstTenPetitions = petitions?.slice(0, 10);

  useEffect(() => {
    let isLogged = checkUserLogin();
    setIsLoggedIn(isLogged);
  }, [isLoggedIn]);

  useEffect(() => {
    if (isUserVerified()) {
      setIsVerified(true);
    } else {
      setIsVerified(false);
    }
  }, []);

  useEffect(() => {
    dispatch(getPetitions());
  }, []);

  const [t] = useTranslation("global");
  const createMarkup = (text) => {
    return { __html: text.replace(/\n/g, '<br />') };
  };
  return (
    <div className="home">
      <div className="home__content container">
        <main>
          <div className="home__main-block">
            {/* <img className="home__main-img" src={MainImg} alt="main" /> */}
            <div className="home__main-content">
              <div className="home__main-left">
                <p>Transparency International</p>
                <h1>{t("home_block.name")}</h1>
                <p>{t("home_block.davis")}</p>
              </div>
              <div className="home__main-buttons button">
                <button
                  onClick={() => {
                    if (isLoggedIn && isVerified) {
                      navigate("/create-petition");
                    } else if (isLoggedIn && !isVerified) {
                      setisVerifyAccount(true);
                    } else {
                      setIsShowModal(true);
                    }
                  }}
                  className="button__submit"
                >
                  <span dangerouslySetInnerHTML={createMarkup(t("home_block.p1"))} />
                  <img
                    style={{ width: "12", height: "14", marginLeft: "0.8rem" }}
                    src={BlueArrow}
                    alt="arrow"
                  />
                  <img
                    style={{ width: "12", height: "14", marginLeft: "0.8rem" }}
                    src={ArrowImg}
                    alt="arrow"
                  />
                </button>
                <button
                  onClick={() => navigate("/petitions")}
                  className="button__search"
                >
                  <span dangerouslySetInnerHTML={createMarkup(t("home_block.p2"))} />
                  <img
                    style={{ width: "12", height: "14", marginLeft: "0.8rem" }}
                    src={BlueArrow}
                    alt="arrow"
                  />
                  <img
                    style={{ width: "12", height: "14", marginLeft: "0.8rem" }}
                    src={ArrowImg}
                    alt="arrow"
                  />
                </button>
              </div>
            </div>
          </div>
        </main>
        <div className="petitions container">
          <h3>{t("home.petitions")}</h3>
          <PetitionsFilter />
          {loading ? (
            <div style={{ height: "50vh" }} className="loadingContainer">
              <h3>{t("home.loading")}</h3>
            </div>
          ) : (
            <div className="container">
              {firstTenPetitions?.map((petition) => (
                <Petition key={petition.id} petition={petition} />
              ))}
            </div>
          )}
          <button
            className="petitions__btn"
            onClick={() => navigate("/petitions")}
          >
            {t("home.watchAllPetitions")} <img src={ArrowRight} alt="arrow" />
          </button>
        </div>
        <div className="steps container">
          <h4 className="steps__title">{t("home.howItWorks")}</h4>
          <div className="steps__blocks">
            <div className="steps__block">
              <div className="steps__step">{t("home.step")} 1</div>
              <div className="steps__petition">
                <p>{t("home.submit_sec")}</p>
                <img src={MoreImg} alt="more" />
              </div>
              <p className="steps__info">{t("home.stepOneText")}</p>
            </div>
            <div className="steps__block">
              <div className="steps__step">{t("home.step")} 2</div>
              <div className="steps__petition">
                <p>{t("home.moderation")}</p>
                <img src={MoreImg} alt="more" />
              </div>
              <p className="steps__info">{t("home.stepTwoText")}</p>
            </div>
            <div className="steps__block">
              <div className="steps__step">{t("home.step")} 3</div>
              <div className="steps__petition">
                <p>{t("home.publishment")}</p>
                <img src={MoreImg} alt="more" />
              </div>
              <p className="steps__info">{t("home.stepThreeText")}</p>
            </div>
            <div className="steps__block">
              <div className="steps__step">{t("home.step")} 4</div>
              <div className="steps__petition">
                <p>{t("home.collecting")}</p>
                <img src={MoreImg} alt="more" />
              </div>
              <p className="steps__info">{t("home.stepFourText")}</p>
            </div>
            <div className="steps__block">
              <div className="steps__step">{t("home.step")} 5</div>
              <div className="steps__petition">
                <p>{t("home.consideration")}</p>
                <img src={MoreImg} alt="more" />
              </div>
              <p className="steps__info">{t("home.stepFiveText")}</p>
            </div>
            <div className="steps__block">
              <div className="steps__step">{t("home.step")} 6</div>
              <div className="steps__petition">
                <p>{t("home.decision")}</p>
                <img src={TickImg} alt="tick" />
              </div>
              <p className="steps__info">{t("home.stepSixText")}</p>
            </div>
          </div>
          {isShowModal && (
            <div className="petition-modal">
              <div className="petition-modal__content">
                <div className="petition-modal-top">
                  <p>{t("home.login")}</p>
                  <img
                    src={CloseModal}
                    alt="close"
                    onClick={() => setIsShowModal(false)}
                  />
                </div>
                <div className="petition-modal__middle">
                  {t("home.loginInstruction")}
                </div>
                <hr></hr>
                <div className="petition-modal__bottom">
                  <button
                    className="petition-modal__cancel"
                    onClick={() => setIsShowModal(false)}
                  >
                    {t("home.cancelBtn")}
                  </button>
                  <button
                    className="petition-modal__sign-in"
                    onClick={() => {
                      navigate("/login");
                      setIsShowModal(false);
                    }}
                  >
                    {t("home.loginBtn")}
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="steps__btn container">
            <button
              onClick={() => {
                if (isLoggedIn && isVerified) {
                  navigate("/create-petition");
                } else if (isLoggedIn && !isVerified) {
                  setisVerifyAccount(true);
                } else {
                  setIsShowModal(true);
                }
              }}
            >
              {t("home.submit")}
            </button>
          </div>
          {isVerifyAccount && (
            <div className="petition-modal">
              <div className="petition-modal__content">
                <div className="petition-modal-top">
                  <p>Верифицируйте аккаунт</p>
                  <img
                    src={CloseModal}
                    alt="close"
                    onClick={() => setisVerifyAccount(false)}
                  />
                </div>
                <div className="petition-modal__middle">
                  Чтобы подать петицию вам нужно сначала верифицировать свой
                  аккаунт.
                </div>
                <hr></hr>
                <div className="petition-modal__bottom">
                  <button
                    className="petition-modal__cancel"
                    onClick={() => setisVerifyAccount(false)}
                  >
                    Отмена
                  </button>
                  <button
                    className="petition-modal__sign-in"
                    onClick={() => {
                      navigate("/profile");
                      setisVerifyAccount(false);
                    }}
                  >
                    Перейти к верификации
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
