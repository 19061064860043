import "./PrivacyPolicy.scss";
import MainImage from "../../components/ui/images/Content.png";
import { useTranslation } from "react-i18next";
const PrivacyPolicy = () => {

  const createMarkup = (html) => {
    return { __html: html };
  };

  const [t] = useTranslation("global");
  return (
    <div className='about container'>
      {/*
      <div className='about__image'>
        <img src={MainImage} alt="main" />
      </div>*/}
      <div className='about__info'>
      <div>
          <h1>{t("privacyPolicy.text1h1")}</h1>
      </div>
        <div>
          <h2>{t("privacyPolicy.text1h2")}</h2>
          <p dangerouslySetInnerHTML={createMarkup(t("privacyPolicy.text1"))}></p>
        </div>

        <div>
          <h2>{t("privacyPolicy.text2h2")}</h2>
          <p>{t("privacyPolicy.text2")}</p>
        </div>

        <div>
          <h2>{t("privacyPolicy.text3h2")}</h2>
          <p>{t("privacyPolicy.text3")}</p>
        </div>

        <div>
          <h2>{t("privacyPolicy.text4h2")}</h2>
          <p>{t("privacyPolicy.text4")}</p>
        </div>

        <div>
          <h2>{t("privacyPolicy.text5h2")}</h2>
          <p>{t("privacyPolicy.text5")}</p>
        </div>

        <div>
          <h2>{t("privacyPolicy.text6h2")}</h2>
          <p>{t("privacyPolicy.text6")}</p>
        </div>        
        <div>
          <h2>{t("privacyPolicy.text7h2")}</h2>
          <p>{t("privacyPolicy.text7")}</p>
        </div>

        <div>
          <h2>{t("privacyPolicy.text8h2")}</h2>
          <p>{t("privacyPolicy.text8")}</p>
        </div>

        <div>
          <h2>{t("privacyPolicy.text9h2")}</h2>
          <p>{t("privacyPolicy.text9")}</p>
        </div>

        <div>
          <h2>{t("privacyPolicy.text10h2")}</h2>
          <p>{t("privacyPolicy.text10")}</p>
        </div>

        <div>
          <h2>{t("privacyPolicy.text11h2")}</h2>
          <p>{t("privacyPolicy.text11")}</p>
        </div>

        <div>
          <h2>{t("privacyPolicy.text12h2")}</h2>
          <p>{t("privacyPolicy.text12")}</p>
        </div>

        <div>
          <h2>{t("privacyPolicy.text13h2")}</h2>
          <p>{t("privacyPolicy.text13")}</p>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy